import React from "react"
import {I18nextContext} from 'gatsby-plugin-react-i18next'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/content"

const DatenschutzPage = () => {
  const context = React.useContext(I18nextContext);

  return (
  <Layout>
    <SEO title="Holger Dieffendahl" />
    <Content lang={context.language} type="impressum"/>
  </Layout>
)
}

export default DatenschutzPage
